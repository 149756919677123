import { LitElement, html, css } from "lit";
import { TWStyles } from "../styles/mimic.css.js";

export class Header extends LitElement {
  static styles = [css``, TWStyles];

  static get properties() {
    return {
      title: { type: String },
      burgerVisible: { type: Boolean },
    };
  }

  _handleClick(e) {
    if (this.burgerVisible) {
      this.burgerVisible = false;
      this.shadowRoot.querySelector("#navBarVertical").style.display = "none";
    } else {
      this.burgerVisible = true;
      this.shadowRoot.querySelector("#navBarVertical").style.display = "block";
  }
}

  render() {
    return html`
      <header
        class="color:c1text1 titleFont font-size:md background-color:c1background1"
      >
      
        <div
          class="display:flex width:100% flex-direction:row justify-items:space-between lg?justify-items:stretch"
        >

        <div>
        <div @click="${this._handleClick}" id="burger" class="position:relative display:block cursor:pointer lg?display:none height:10px width:40px display:flex flex-direction:column justify-items:stretch margin:md">
              <div class="border-bottom-width:1px border-bottom-style:solid border-bottom-color:pink flex-grow:1 height:100%"></div>
              <div class="border-bottom-width:1px border-bottom-style:solid border-bottom-color:pink flex-grow:1 height:100%"></div>
              <div class="border-bottom-width:1px border-bottom-style:solid border-bottom-color:pink flex-grow:1 height:100%"></div>
            </div>
        <nav id="navBarVertical" style="display:none;transition-name:navBar;position:absolute;top:50px" class="font-size:md background:black padding:md">
              <ul
                class="justify-content:space-around color:c1text1 text-decoration:none list-style:none display:flex flex-direction:column"
              >
                <li
                  class="margin-left:sm color:c1text1"
                >
                  <a class="color:c1text2 text-decoration:none" href="/download.html">Download</a>
                </li>
                <li
                  class="margin-left:sm color:c1text1"
                >
                  <a class="color:c1text2 text-decoration:none" href="/tutorials.html">Tutorials</a>
                </li>
                <li
                  class="margin-left:sm color:c1text1"
                >
                  <a class="color:c1text2 text-decoration:none" href="/implementation.html">Implementation</a>
                </li>
                <li
                  class="margin-left:sm color:c1text1"
                >
                  <a class="color:c1text2 text-decoration:none" href="/usinglivevisualisation.html">Learn</a>
                </li>
              </ul>
            </nav>
            </div>
          <a
            class="color:c1text1 display:flex flex-direction:row width:100%  align-items:center justify-self:center text-decoration:none flex-grow:1"
            href="/index.html"
          >
            <div class="display:none lg?display:block"
            >
              <img src="/Favicon 64x64.png" width="64" alt="VisualGit Logo" />
            </div>
            <div
              class="font-weight:md flex-grow:1 lg?flex-grow:0 user-select:none text-align:center font-size:xl color:c1text2"
            >
              VisualGIT
            </div>
          </a>

       
            
            <nav id="navBarHorizontal" class="padding:sm width:100% display:none lg?display:block">
              <ul
                class="justify-content:space-around color:c1text1 text-decoration:none font-size:lg list-style:none display:flex flex-direction:row"
              >
                <li
                  class="margin-left:sm color:c1text2"
                >
                  <a class="color:c1text2 text-decoration:none" href="/download.html">Download</a>
                </li>
                <li
                  class="margin-left:sm color:c1text1"
                >
                  <a class="color:c1text2 text-decoration:none" href="/tutorials.html">Tutorials</a>
                </li>
                <li
                  class="margin-left:sm color:c1text1"
                >
                  <a class="color:c1text2 text-decoration:none" href="/implementation.html">Implementation</a>
                </li>
                <li
                  class="margin-left:sm color:c1text1"
                >
                  <a class="color:c1text2 text-decoration:none" href="/usinglivevisualisation.html">Learn</a>
                </li>
              </ul>
            </nav>
            
          
          <a
            target="_blank"
            class="text-decoration:none color:blue:hover margin:md"
            href="https://github.com/MarkJamesHoward/VisualGitCmd"
          >
            <img width="50" src="/github-mark.png" alt="GitHub Cat" />
          </a>
        </div>
        
      </header>
    `;
  }
}



customElements.define("visualgit-header", Header);
